<template>
  <div id="app">
    <md-content>
        <a href="/">
          <img alt="Logo Design Group Logo" src="./assets/ldg_new.png" class="logo">
        </a>
        <NavigationLinks />
        <div>
          <PhotoGrid :images="images" />
        </div>
    </md-content>
    <div class="footer">
      &copy; 2020 Logo Design LLC
    </div>
  </div>
</template>

<script>
import NavigationLinks from './components/NavigationLinks.vue'
import PhotoGrid from './components/PhotoGrid.vue'
import { imageList } from './images.js';

export default {
  name: 'App',
  components: {
    NavigationLinks,
    PhotoGrid
  },
  data() {
    return imageList;
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.footer {
  padding: 16px 0 0 0;
}

.logo {
  padding: 20px 0 0 0;
}
</style>