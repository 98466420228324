const imageList = { 
    images: [
        {
            "id": 1,
            "src": "/gallery/sign-1.jpg",
            "name": "Mathnasium",
            "location": "NWC of Tatum & Cave Creek",
            "lat": "33.756193",
            "lng": "-111.9951597"
        }, {
            "id": 2,
            "src": "/gallery/sign-2.jpg",
            "name": "Salon Boutique",
            "location": "Riverview in Mesa Along Loop 101",
            "lat": "33.4353936",
            "lng": "-111.8649011"
        }, {
            "id": 3,
            "src": "/gallery/sign-3.jpg",
            "name": "Superior Blinds",
            "location": "Indian School West of Dysart Rd",
            "lat": "0",
            "lng": "0"
        }, {
            "id": 4,
            "src": "/gallery/sign-4.jpg",
            "name": "Brooklyn's Pizza",
            "location": "Rittenhouse & Ellsworth",
            "lat": "0",
            "lng": "0"
        }, {
            "id": 5,
            "src": "/gallery/sign-5.jpg",
            "name": "Herbal Nails & Spa",
            "location": "Baseline & 43rd Ave",
            "lat": "0",
            "lng": "0"
        }, {
            "id": 6,
            "src": "/gallery/sign-6.jpg",
            "name": "",
            "location": "",
            "lat": "0",
            "lng": "0"
        }, {
            "id": 7,
            "src": "/gallery/sign-7.jpg",
            "name": "",
            "location": "",
            "lat": "0",
            "lng": "0"
        }, {
            "id": 8,
            "src": "/gallery/sign-8.jpg",
            "name": "",
            "location": "",
            "lat": "0",
            "lng": "0"
        }, {
            "id": 9,
            "src": "/gallery/sign-9.jpg",
            "name": "",
            "location": "",
            "lat": "0",
            "lng": "0"
        }, {
            "id": 10,
            "src": "/gallery/sign-10.jpg",
            "name": "",
            "location": "",
            "lat": "0",
            "lng": "0"
        }, {
            "id": 11,
            "src": "/gallery/sign-11.jpg",
            "name": "",
            "location": "",
            "lat": "0",
            "lng": "0"
        }, {
            "id": 12,
            "src": "/gallery/sign-12.jpg",
            "name": "",
            "location": "",
            "lat": "0",
            "lng": "0"
        }, {
            "id": 13,
            "src": "/gallery/sign-13.jpg",
            "name": "",
            "location": "",
            "lat": "0",
            "lng": "0"
        }, {
            "id": 14,
            "src": "/gallery/sign-14.jpg",
            "name": "",
            "location": "",
            "lat": "0",
            "lng": "0"
        },
        
    ]
};

export { imageList };