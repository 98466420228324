<template>
    <div class="grid-wrapper">
        <Stack :column-min-width="500" :gutter-width="8" :gutter-height="8" monitor-images-loaded>
            <stack-item v-for="(item, i) in images" :key="i">
                <img :src="item.src" />
            </stack-item>
        </Stack>
    </div>
</template>

<script>
  import { Stack, StackItem } from 'vue-stack-grid';

export default {
  name: 'PhotoGrid',
  props: {
    images: Array
  },
  components: { Stack, StackItem }
}
</script>

<style>
.grid-wrapper {
    padding: 0 20px;
}
</style>